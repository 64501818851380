.el-tree-node.is-current > .el-tree-node__content {
  background-color: #f3b6b6 !important;
  color: #1e2c5a;
}
.el-tree-node__content:hover {
  background-color: #66b1ff87;
}
.tree-scroll {
  width: 180px;
  border: 1px solid #f5dbdb;
  height: 100%;
}
.el-table th {
  padding: 0 ;
}
.el-table thead {
  height: 15px !important;
  color: #808080;
  font-size: 12px;
}
.el-table td {
  padding: 5px ;
  font-size: 11px;
}
.el-dialog__body {
  padding: 10px;
}
.el-dialog__header {
  padding: 0px;
}
.el-dialog__footer {
  padding: 5px;
  border-top: 1px solid #ccc;
}
.el-dialog__headerbtn {
  font-size: 20px;
  background: white;
  top: 5px !important;
}
.messageBox .el-message-box__content {
  border-top: 1px solid #EBEEF5;
  height: 50px;
}
.el-message-box__message p {
  line-height: 30px;
}
.messageBox .el-message-box__btns {
  padding: 20px 15px 0;
}
.messageBox .el-message-box__header {
  background: #409eff;
}
.messageBox .el-message-box__title {
  color: white ;
  font-size: 14px;
}
