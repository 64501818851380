

















































































































































































































































































































































































































.selinput{
    width:340px;
    margin-left:10px; 
}
.colwidth{
    width:140px;
}
.colwidth2{
    width:642px;
}
.pub_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        }
    }
} 
.item-width  {
    width: 48%;
    color:#409EFF;
}
